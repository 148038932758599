html, body, #root {
    height: 100%;
    /* width: 90%; 
    margin: 0 auto;  */
}

body {
    width: 90%;
    margin: 0 auto; 
    padding: 0;
    background-color: #FA9E93;
}

.flex-grid-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .15);
}

.flex-grid-item {
    flex: 1 0 calc(25%);
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    height: 20%;
    border: 1px solid #000000;
}

.flex-grid-item-50-a {
    flex-basis: calc(50%);
    height: 75%;
}

.flex-grid-item-50a {
    flex-basis: calc(50%);
    height: 75%;
    background-color: #FA9E93;
}

.leftHolder {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
}

.homeHeader {
    font-family: 'Nourd-Bold','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 40px;
    font-weight: bold;
    padding-top: 30px;
}

.homeText {
    font-family: 'Nourd-Regular','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
}

.socialText {
    font-family: 'Nourd-Semi-Bold','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 10px;
    font-weight: normal;
    color: #FFED66;
}

.flex-grid-item-50b {
    flex-basis: calc(50%);
    height: 15%;
    background-color: #0F291B;
    margin: 0;
    border: 0;
}

.flex-grid-item-100 {
    flex-basis: calc(100%);
    box-sizing: border-box;
    text-align: center;
    height: 25%;
    background-image: url('../public/Vybe-Bg.png');
    background-size: cover;
    position: relative;
}

.flex-grid-item-25-a {
    flex: 1 0 calc(25%);
    background-color: #FA9E93;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    height: 250px;
    border: 1px solid #000000;
    font-family: 'Nourd-Regular',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    position: relative;
}

.flex-grid-item-25-b {
    flex: 1 0 calc(25%);
    background-color: #FFED66;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    height: 250px;
    border: 1px solid #000000;
    border-left: 0;
    font-family: 'Nourd-Regular',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    position: relative;
}

.flex-grid-item-25-c {
    flex: 1 0 calc(25%);
    background-color: #DAD6FF;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    height: 250px;
    border: 1px solid #000000;
    border-left: 0;
    font-family: 'Nourd-Regular',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    position: relative;
}

.flex-grid-item-25-d {
    flex: 1 0 calc(25%);
    background-color: #B1EFE2;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    height: 250px;
    border: 1px solid #000000;
    border-left: 0;
    font-family: 'Nourd-Regular',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    position: relative;
}

.grid-image {
    width: 100vw;
    max-width: 100%;
    height: auto;
    display: block;
    /* margin: auto; */
    object-fit: contain;
    float: right;
}

.flex-grid-item-25-a img,
.flex-grid-item-25-b img,
.flex-grid-item-25-c img,
.flex-grid-item-25-d img {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.imageOverlay {
    margin: 0 auto;
    margin-top: 60px;
    width: 40vw;
    max-width: 100%;
}

.myBox {
    height: 80px;
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submit-button {
    padding: 10px 20px;
    background-color: #B1EFE2;
    color: #FA9E93;
    border: none;
    cursor: pointer;
    
}

.submit-button:hover {
    background-color: #B1EFE2;
}

.social-icons {
    display: flex;
    justify-content: left;
    padding-bottom: 40px;
}

.social-icon {
    /* width: 50px; */
    height: 50px;
    padding-right: 12px;
    /* margin: 0 5px; */
}

.footerTitle {
    color: #FA9E93;
    font-family: 'Nourd-Semi-Bold',Verdana, Geneva, Tahoma, sans-serif;
    font-size: 10px;
    padding-bottom: 10px;
}

.footerText {
    color: #FFFDF4;
    font-family: 'Nourd-Regular', Verdana, Geneva, Tahoma, sans-serif;
    font-size: 10px;
    line-height: 15px;
}

.footerTextEmail {
    color: #FFFDF4;
    font-family: 'Nourd-Semi-Bold',Verdana, Geneva, Tahoma, sans-serif;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 10px;
}

.footerHolder {
    width: 90%;
    margin: 0 auto;
    padding-top: 23px;
}

.formContainer {
    display: flex;
    width: 400px;
    padding-top: 10px;
    padding-bottom: 40px;
}

.textArea {
    height: 20px;
    width: 100%;
    padding: 10px;
    resize: none;
    border: 0;
    font-family: 'Nourd-Medium',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 12px;
}

.submitButton {
    height: 40px;
    width: 200px;
    margin-left: 0px;
    background-color: #B1EFE2;
    color: #FA9E93;
    border: none;
    font-family: 'Nourd-Medium',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 14px;
}

.subTitle {
    font-family: 'Nourd-Heavy',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
    font-size: 14px;
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
    html, body, #root {
        height: 100%;
        width: 100%; 
        margin: 0 auto; 
        overflow-x: hidden;
    }
    .leftHolder {
        width: 100%;
        margin: 0 auto;
        padding-top: 70px;
    }
    .flex-grid-item-50-a {
        flex-basis: calc(100%);
        height: 500px;
    }
    
    .flex-grid-item-50a {
        flex-basis: calc(100% - 20px);
        height: 90%;
        background-color: #FA9E93;
        padding: 10px;
    }
    .grid-image {
        width: 100vw;
        max-width: 100%;
        height: auto;
        display: block;
        margin: auto;
        object-fit: cover;
    }
    .imageOverlay {
       
        margin-top: 50px;
        margin-right: 100px;
        width: 100vw;
        max-width: 100%;
        height: auto;
    }
    .flex-grid-item-25-a {
        flex: 1 0 calc(100%);
        background-color: #FA9E93;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        height: 300px;
        border: 1px solid #000000;
        font-family: 'Nourd-Regular',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        position: relative;
    }
    
    .flex-grid-item-25-b {
        flex: 1 0 calc(100%);
        background-color: #FFED66;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        height: 300px;
        border: 1px solid #000000;
        border-left: 0;
        font-family: 'Nourd-Regular',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        position: relative;
    }
    
    .flex-grid-item-25-c {
        flex: 1 0 calc(100%);
        background-color: #DAD6FF;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        height: 300px;
        border: 1px solid #000000;
        border-left: 0;
        font-family: 'Nourd-Regular',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        position: relative;
    }
    
    .flex-grid-item-25-d {
        flex: 1 0 calc(100%);
        background-color: #B1EFE2;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        height: 300px;
        border: 1px solid #000000;
        border-left: 0;
        font-family: 'Nourd-Regular',Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        position: relative;
    }

    .flex-grid-item-50b {
        flex-basis: calc(100%);
        height: 25%;
        background-color: #0F291B;
    }
    
}

@font-face {
    font-family: 'Nourd-Bold';
    src: url('./Nourd/nourd_bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nourd-Regular';
    src: url('./Nourd/nourd_regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nourd-Semi-Bold';
    src: url('./Nourd/nourd_semi_bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Nourd-Medium';
    src: url('./Nourd/nourd_medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Nourd-Heavy';
    src: url('./Nourd/nourd_heavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }